import {createReducer} from '@reduxjs/toolkit'
import {describeSeatingPlan, selectSeat, unselectSeat, selectSeats} from '../actions/seating'
import {SeatingState} from '../types'
import {checkout} from '../actions/checkout'
import {closeNativeModal} from '../actions/modals'

const defaultState: SeatingState = {
  plan: null,
  loading: false,
  selectedSeats: [],
}

export const seating = createReducer<SeatingState>(defaultState, builder => {
  builder
    .addCase(describeSeatingPlan.fulfilled, (state, action) => ({...state, plan: action.payload.plan}))
    .addCase(selectSeat, (state, action) => ({...state, selectedSeats: [...state.selectedSeats, action.payload]}))
    .addCase(selectSeats, (state, action) => ({...state, selectedSeats: [...state.selectedSeats, ...action.payload]}))
    .addCase(unselectSeat, (state, action) => ({
      ...state,
      selectedSeats: state.selectedSeats.filter(seat => seat.id !== action.payload),
    }))
    .addCase(checkout.pending, state => ({...state, loading: true}))
    .addCase(closeNativeModal, state => ({...state, loading: false}))
})
