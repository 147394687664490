import {IWixAPI} from '@wix/native-components-infra/dist/src/types/types'
import {
  getAboutText,
  getPreliminaryInvoice,
  getTaxConfig,
  isEndDateHidden,
  isLocationTbd,
} from '@wix/wix-events-commons-statics'
import {EventStatus, EventType, RegistrationStatus, LocationType} from '@wix/events-types'
import {getPageUrl} from '../../../commons/utils/wix-code-api'
import {State} from '../types'

export const renderSEOTags = async (wixCodeApi: IWixAPI, state: State) => {
  const pageUrl = await getPageUrl(wixCodeApi)
  const itemData = getItemData(state, pageUrl)
  const seoData = state.event.seoSettings?.advancedSeoData ?? {}

  if (itemData) {
    wixCodeApi.seo.renderSEOTags({
      itemType: 'EVENTS_PAGE',
      itemData,
      seoData,
    })
  }
}

const getItemData = (state: State, pageUrl: string) => {
  try {
    return {
      event: transformEvent(state.event, pageUrl),
      tickets: state.tickets?.length ? transformTickets(state.tickets, state.event) : state.tickets,
    }
  } catch (e) {
    console.warn(e)
    return null
  }
}

export const transformEvent = (event: wix.events.Event, pageUrl: string) => ({
  ...event,
  about: getAboutText(event),
  status: EventStatus[event.status],
  eventPageUrl: {
    base: pageUrl,
    path: `/${event.slug}`,
  },
  registration: {
    ...event.registration,
    type: EventType[event.registration.type],
    status: RegistrationStatus[event.registration.status],
  },
  location: {
    ...event.location,
    type: isLocationTbd(event) ? undefined : LocationType[event.location.type],
  },
  scheduling: {
    ...event.scheduling,
    config: {
      ...event.scheduling.config,
      endDate: isEndDateHidden(event) ? undefined : event.scheduling.config.endDate,
    },
  },
})

export const transformTickets = (tickets: wix.events.ticketing.TicketDefinition[], event: wix.events.Event) => {
  return tickets.map(ticket => ({
    ...ticket,
    finalPrice: {
      ...getPreliminaryInvoice(tickets, {[ticket.id]: {quantity: 1}}, getTaxConfig(event)).grandTotal,
    },
  }))
}
