import {IWidgetControllerConfig} from '@wix/native-components-infra/dist/src/types/types'
import {GenericAPI, EventsHttpClient} from '@wix/wix-events-commons-statics'
import {ControllerParams} from '@wix/yoshi-flow-editor'
import {instanceGetterFactory} from '../services/instance'
import {getLinguisticHeader} from '../services/multilingual'
import {getLanguage, getWriteServerBaseUrl, getLocale, getTimezone, getServerBaseUrl} from './wix-code-api'

export class Api {
  controller: IWidgetControllerConfig
  getInstance: () => string
  instanceId: string
  compId: string
  viewMode: string
  language: string
  locale: string
  tz: string
  api: GenericAPI | EventsHttpClient
  writeApi: GenericAPI | EventsHttpClient
  registrar: Dictionary<Function>

  constructor(controllerParams: ControllerParams) {
    this.controller = controllerParams.controllerConfig
    this.getInstance = instanceGetterFactory(this.controller)
    this.instanceId = this.controller.appParams.instanceId
    this.compId = this.controller.compId
    this.viewMode = this.controller.wixCodeApi.window.viewMode.toLowerCase()
    this.language = getLanguage(this.controller.wixCodeApi)
    this.locale = getLocale(this.controller.wixCodeApi)
    this.tz = getTimezone(this.controller.wixCodeApi)
    const newApiEnabled = controllerParams.flowAPI.experiments.enabled('specs.events.ui.NewHttpClient') ?? false

    if (newApiEnabled) {
      this.api = new EventsHttpClient(controllerParams)
      this.writeApi = new EventsHttpClient(controllerParams, getWriteServerBaseUrl(this.controller.wixCodeApi))
    } else {
      this.api = new GenericAPI(
        getServerBaseUrl(this.controller.wixCodeApi),
        () => this.getHeaders(this.controller),
        'omit',
      )
      this.writeApi = new GenericAPI(
        getWriteServerBaseUrl(this.controller.wixCodeApi),
        () => this.getHeaders(this.controller),
        'omit',
      )
    }
  }

  getHeaders = (controller: IWidgetControllerConfig) => {
    const linguisticHeader = getLinguisticHeader(controller)

    const headers = {
      Authorization: this.getInstance(),
      'Content-Type': 'application/json',
    }

    if (linguisticHeader) {
      headers['x-wix-linguist'] = linguisticHeader
    }

    return headers
  }

  get(name: string) {
    const api = this.registrar[name]

    if (api) {
      return api
    }

    throw new Error(`API METHOD IS NOT REGISTERED ${name}`)
  }
}
