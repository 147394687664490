import {DescribeSeatingPlanResponse} from '@wix/ambassador-seating-public/types'
import {createAction, createAsyncThunk} from '@reduxjs/toolkit'
import {getEventId} from '@wix/wix-events-commons-statics'
import {SelectedSeat} from '../types/seating'
import {ThunkConfig} from './interfaces'

export const describeSeatingPlan = createAsyncThunk<DescribeSeatingPlanResponse, void, ThunkConfig>(
  'DESCRIBE_SEATING_PLAN',
  async (_, {getState, extra: {serverApi}}) => serverApi.describeSeatingPlan(getEventId(getState().event)),
)

export const selectSeat = createAction<SelectedSeat>('SELECT_SEAT')

export const selectSeats = createAction<SelectedSeat[]>('SELECT_SEATS')

export const unselectSeat = createAction<string>('UNSELECT_SEAT')
